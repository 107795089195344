import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Herobanner from "../components/herobanner";
import ContactContainer from "../components/elements/contactContainer";

import Container from "../components/containers/container";
import Box from "../components/containers/box";

import IconMiniTitle from "../components/elements/iconMiniTitle";
import IconsListOrizontal from "../components/icons-list/orizontal/IconsListOrizontal";
import IconsListOrizontalIcon from "../components/icons-list/orizontal/iconsListOrizontalIcon";

const ChiSiamoPage = () => (
  <Layout>
    <Helmet>
      <title>Chi Siamo</title>
      <meta
        name='description'
        content='Siamo nati nel 1976 da un’idea di Claudio Scarpa, dapprima come impresa a conduzione familiare, per poi crescere negli anni fino a diventare un’importante realtà del territorio.'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='' />
      <meta property='og:image' content='/image/social/og-contatti-elettronica-scarpa.png' />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='' />
      <meta property='twitter:title' content='Chi Siamo | Elettronica scarpa' />
      <meta
        property='twitter:description'
        content='Siamo nati nel 1976 da un’idea di Claudio Scarpa, dapprima come impresa a conduzione familiare, per poi crescere negli anni fino a diventare un’importante realtà del territorio.'
      />
      <meta property='twitter:image' content='/image/social/og-contatti-elettronica-scarpa.png' />
    </Helmet>
    <Herobanner background='chi-siamo'>
      <h1 className='mb-6'>Una passione che ha più di 40 anni. </h1>
    </Herobanner>
    <Container notAligned={true}>
      <Box>
        <div className='row'>
          <div className='col-12 col-lg-5 offset-lg-1'>
            <p className='mb-6'>
              Siamo nati nel 1976 da un’idea di Claudio Scarpa, dapprima come impresa a conduzione familiare, per poi crescere negli anni fino a diventare un’importante realtà del territorio.
              <br />
              Ci occupiamo di elettronica e automazione industriale applicata a vari settori produttivi (tessile, meccanotessile, metalmeccanico, fondiario, packaging, trattamento acque e così via), e
              fino ad oggi contiamo più di 2000 installazioni in tutto il mondo. Ci piace definirci affidabili e all’avanguardia: le nostre soluzioni rendono le aziende più competitive e pronte per
              affrontare il futuro.
            </p>
            <div className='row'>
              <div className='col-6'>
                <a href='http://www.aziendaqualitaitaliana.it' target='_blank' rel='noreferrer' title='Elettronica Scarpa è Azienda di Qualità Italiana'>
                  <img alt='Elettronica Scarpa è Azienda di Qualità Italiana' className='img-fluid mb-2 border-0' src='/image/azq.jpg' />
                </a>
              </div>
              <div className='col-6'>
                <img className='img-fluid mb-2 border-0' alt='firme' src='/image/firme.jpg' />
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-4 offset-lg-1'>
            <div className='row'>
              <div className='col-12'>
                <img className='img-fluid mb-2' alt='homepage' src='/image/chi-1.jpg' />
              </div>
              <div className='col-12'>
                <img className='img-fluid mb-2' alt='homepage' src='/image/chi-2.jpg' />
              </div>
              <div className='col-12'>
                <a className='e-button e-button--block e-button--flex' href='brochure-elettronica-scarpa.pdf' title='Elettronica Scarpa - Brochure'>
                  Scarica la brochure aziendale
                  <svg version='1.1' id='Livello_1' x='0px' y='0px' viewBox='0 0 24 24'>
                    <path className='st0' d='M19,20.5v-2H5v2H19z' />
                    <g className='arrow'>
                      <polygon className='st0' points='19,9.5 15,9.5 15,3.5 9,3.5 9,9.5 5,9.5 12,16.5 	' />
                      <polygon className='st0' points='11,11.5 11,5.5 13,5.5 13,11.5 14.2,11.5 12,13.7 9.8,11.5 	' />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Container>

    <Container customClass='py-2' />
    <Container customClass='py-10'>
      <div className='col-10 offset-1 col-md-12 offset-md-0'>
        <IconMiniTitle>I nostri punti di forza</IconMiniTitle>
        <h3 className='mt-4 mb-9'>Tre motivi per collaborare con noi. </h3>
        <IconsListOrizontal>
          <IconsListOrizontalIcon title={"Prodotti certificati"} icon={"qualita"}>
            Puntiamo alla perfezione: i nostri prodotti vengono sottoposti a rigidi protocolli per garantire la massima qualità.
          </IconsListOrizontalIcon>
          <IconsListOrizontalIcon title={"Supporto tecnico"} icon={"supporto"}>
            Non siamo dei semplici fornitori. Vorremmo diventare il tuo partner tecnologico: puoi contare su di noi, sempre.
          </IconsListOrizontalIcon>
          <IconsListOrizontalIcon title={"Aggiornamento costante"} icon={"persone"}>
            Dietro a ogni prodotto c’è un gruppo di persone brillanti, sempre in aggiornamento, che guarda dritto al futuro.
          </IconsListOrizontalIcon>
        </IconsListOrizontal>
      </div>
    </Container>
    <Container customClass='py-2' />

    <ContactContainer />
  </Layout>
);

export default ChiSiamoPage;
